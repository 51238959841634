import React from "react";
import "./LiveCasinoGameThumbnail.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { prettyUrl } from "../../utils/UrlUtils";
import { LiveCasinoGame, LiveCasinoGameTranslation } from "../../http/protocol";
import { t } from "../../i18n";
import { Device } from "../../utils/Device";
import Translator, { Translation } from "../../Translator";
import BackgroundUrlTranslator from "../../BackgroundUrlTranslator";


interface Props extends RouteComponentProps {
    game: LiveCasinoGame;
    loggedIn?: boolean;
    updateSearchValue: (str: string) => void;
    favourite?: boolean;
    toggleFavourite: (id: string) => void;
    compact?: boolean;
    showLogin?: () => void;
    activeLng?:string;
};

interface State {
    show: boolean;
}

const toLogin = (props: Props) => {
    if (props.game && props.game.Name) {
        props.history.push("/playnow");
    }
}


function getClassNames(compact?: boolean) {
    if (compact) {
        return "livecasino-game col-lg-2 col-md-4 col-sm-4 col-4";
    } else {
        return "livecasino-game col-lg-2 col-md-4 col-sm-4 col-4";
    }
}
function mapTranslations(g?: LiveCasinoGame): Translation[] {
    if (!g || !g.translations) {
        return [];
    }
    return g.translations.map((t: LiveCasinoGameTranslation) => ({ lng: t.language, value: t.imageUrl }));
}
// function addVideoBg(state: State) {
//     console.error(state);
// }
export class LiveCasinoGameThumbnail extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);

        this.state = { show: false };
        this.showVideoOnHover = this.showVideoOnHover.bind(this);

        this.hideVideoOnHover = this.hideVideoOnHover.bind(this);
        this.launchGame = this.launchGame.bind(this);
    }
    showVideoOnHover() {
        this.setState({ show: true });
    }
    hideVideoOnHover() {
        this.setState({ show: false });
    }
    launchGame(props: Props, playforreal?: boolean) {
        if (props.updateSearchValue) {
            props.updateSearchValue("");
        }

        if (props.game && props.game.Name && !playforreal) {
            props.history.push(`/livecasino/playforfun/${props.game.GameLaunchId}/${prettyUrl(props.game.Name)}`)
        } else if (props.game && props.game.Name && playforreal) {
            props.history.push(`/livecasino/playforreal/${props.game.GameLaunchId}/${prettyUrl(props.game.Name)}`)
        }
    }

    lauchMobileGameView(props: Props) {

        if (props.game && props.game.Name && Device.isMobile() ) {
            props.history.push(`/livecasino/game/${props.game.GameLaunchId}/${prettyUrl(props.game.Name)}`)
        } 
        
    }
    backToTop() {
  

        window.scrollTo({top: 0, behavior: 'auto'});
    }
    render() {

        let screeshots ;

            screeshots  =  "https://content.adminemx.com/" + this.props.game.ProductCode + '/' + this.props.game.SubProductCode  + '/' + this.props.game.GameId  + '/' + "screen.png";
            let isScreen = this.props.game.Tags?.includes("isScreen");
        let gameIcon ;
     
            gameIcon  = "https://content.adminemx.com/" + this.props.game.ProductCode + '/' + this.props.game.SubProductCode  + '/' + this.props.game.GameId  + '/' + "icon.png";
       
        return (
            <div className={Device.isMobile() ? "livecasino-game mobile" : getClassNames(this.props.compact)} key={this.props.game.GameId}  >
                <div className={"game-box"}   >
                    <div className="content" onMouseDown={() =>this.lauchMobileGameView(this.props)} onMouseEnter={() => this.showVideoOnHover()} onMouseLeave={() => this.hideVideoOnHover()}   onPointerDown={() => { this.props.loggedIn ? this.launchGame( this.props,true):(this.props.showLogin && this.props.showLogin())}}>

                        <BackgroundUrlTranslator className={"thumbnail thumb-bg"} defaultTranslation={gameIcon} translations={mapTranslations(this.props.game)} >
                            
                             
                              { this.state.show && this.props.game.GameTrailerNameDESKTOP ? (<video
                                autoPlay
                                muted
                                crossOrigin="anonymous" 
                                src={`../../../assets/livecasinoVideo/${this.props.game.GameTrailerNameDESKTOP}.mp4`}
                                loop />) : isScreen ? (<div className="ssLiveCasino" style={{backgroundImage :`url(https://content.adminemx.com/${screeshots})` }}></div>): null } 

                           



                           {Device.isMobile() ? null : (<div className={"play-now"} >
                                <div className={"game-title"}>

                                    {this.props.loggedIn ? (
                                        <button className="btn btn-primary  btn-rounded" onPointerDown={() => this.launchGame(this.props, true)}>{t("playForReal")}</button>
                                    ) : (
                                        <button className="btn btn-primary  btn-rounded" onPointerDown={() => this.props.showLogin && this.props.showLogin()}>{t("playForReal")}</button>
                                    )
                                    }
                                     {this.props.game.Tags?.includes("isDemoEnabled") ? (
                                    <button className="btn btn-primary  btn-rounded play-for-fun" onPointerDown={() => this.launchGame(this.props, false)}>{t("playForFun")}</button>
                                     ) : null}
                                </div>
                            </div>) }

                            
                        </BackgroundUrlTranslator>
                        {this.props.game.isTopGame ||  this.props.game.Tags?.includes("isPopular") ? (<div className="popular">    <i className="ri-star-fill"></i>{this.props.activeLng === "ja-JP" ? "人気" :  "Popular"} </div>): null }
                        {this.props.game.Tags?.includes("isNewGame") ? (<div className="newgame">{t("new")} </div>): null }
                    </div>
                    <div className={"game-description"}>
                                      
                                      <div className={"game-text-container"}>
                                          <div className={"content game-name"} >
                                              <div className={"provider"}> {this.props.activeLng === "ja-JP" ? this.props.game && this.props.game.NameJP :  this.props.game && this.props.game.Name }</div>
                                              {/* {props.game && props.game.Name && props.game.translations &&
                                              <Translator defaultTranslation={props.game.Name} translations={props.game.translations.map(t=>({lng : t.language, value : t.name})) as any} />} */}
                                          </div>
                                          <div className={"meta"}>
                                              <div className={"provider"}>{this.props.game && this.props.game.SubProductCode}</div>
                                          </div>
                                      </div>
      
                                      <div className={"favourite-container"} onClick={() => this.props.game && this.props.game.GameId && this.props.toggleFavourite && this.props.toggleFavourite(this.props.game.GameId)}>
                                          {/* <a className={"btn favourite " + (this.props.favourite ? "selected" : "")}><i className={"oi oi-heart"}></i></a> */}
                                          <span className={`like ${this.props.favourite ? "active" : ""}`}>
                                              <i className="ri-heart-line"></i>
                                          </span>
                                      </div>
                                  </div>

                </div>
            </div>
        )
    };
}


export default withRouter<Props>(LiveCasinoGameThumbnail);