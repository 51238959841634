import axios, {AxiosInstance} from "axios";

import { createProxyMiddleware, Filter, Options, RequestHandler } from 'http-proxy-middleware';

export class HttpService {
    axios : AxiosInstance;
    lastRequest :number;
    constructor(private baseUrl : string, private casinoUrl:string, private liveCasinoUrl:string, private brandId:number) {
        this.lastRequest = Date.now();
        this.axios = axios.create({
            headers : {
                "Content-type" : "application/json"
            }
        })
    }
    getTimeSinceLastRequest() : number {
        return Date.now() - this.lastRequest;
    }
    get<R>(url : string) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + url +`&brandId=${this.brandId}`, { withCredentials : false })
                .then(response => {resolve(response.data)})
                .catch(e => this.handleError(url,e,reject))
        })
    }
    getConstumeUrl<R>(url : string) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(url +`&brandId=${this.brandId}`, { withCredentials : false })
                .then(response => {resolve(response.data)})
                .catch(e => this.handleError(url,e,reject))
        })
    }
    getDoctype<R>(url : string) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + url , { withCredentials : false })
                .then(response => {resolve(response.data)})
                .catch(e => this.handleError(url,e,reject))
        })
    }
    getCasinoGame(url : string)  {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + url  +`&brandId=${this.brandId}` , { 
                 withCredentials : false,
                 headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Credentials':true,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With, Accept',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                
            }
         })
                .then(response => {
                  
                    return resolve(response.request.res.responseUrl)})
                .catch(e =>{ this.handleError(url,e,reject)})
        })
    }
    getLiveCasinoGame(url : string)  {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.baseUrl + url  +`&brandId=${this.brandId}`, { 
                 withCredentials : false, 
                 headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Credentials':true,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With, Accept',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                
            }
         })
                .then(response => {
                  
                    return resolve(response.request.res.responseUrl)})
                .catch(e =>{ this.handleError(url,e,reject)})
        })
    }

    getCasinoLobby<R>(): Promise<R>  {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.casinoUrl, { withCredentials : false })
                .then(response => {return resolve(response.data)})
                .catch(e => this.handleCasinoError(e,reject))
        })
    }
    getLiveCasinoLobby<R>(): Promise<R>  {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(this.liveCasinoUrl, { withCredentials : false })
                .then(response => {return resolve(response.data)})
                .catch(e => this.handleCasinoError(e,reject))
        })
    }
    getSong88Lobby<R>(url : string): Promise<R>  {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.get(url, { 
                 withCredentials : false,
                 headers: {

                "Content-type" : " application/json; text/xml; charset=utf-8",
                    'Access-Control-Allow-Credentials':true,
                    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
                
            }
         })
                .then(response => {
                    // console.error(response.request.res.responseUrl,"response.request.res.responseUrl");
                    return resolve(response.request.res.responseUrl)})
                .catch(e =>{ this.handleError(url,e,reject)})
        })
    }
    getCountryAvailable<R>() : Promise<R> {
        this.lastRequest = Date.now();
        const sessionKey = localStorage.getItem("sessionKey");
        return  new Promise((resolve,reject)=> {
            this.axios.post(this.baseUrl + `/ips/getCountries?&brandId=${this.brandId}`, { withCredentials : false,
             headers: {
                    'Cookie': `SESSIONID=F188ADD5B55DACFDD5628F246936E776; brandid=${this.brandId}`,
                 }
                })
                .then(response => {resolve(response.data)})
                .catch(e => this.handleError("/getCountries?",e,reject))
        })
    }
    getBrandCurrencies<R>() : Promise<R> {
        this.lastRequest = Date.now();
        const sessionKey = localStorage.getItem("sessionKey");
        return  new Promise((resolve,reject)=> {
            this.axios.post(this.baseUrl + `/ips/getBrandCurrencies?&brandId=${this.brandId}`, { withCredentials : false,
             headers: {
                    'Cookie': `SESSIONID=F188ADD5B55DACFDD5628F246936E776; brandid=${this.brandId}`,
                 }
                })
                .then(response => {resolve(response.data)})
                .catch(e => this.handleError("/getBrandCurrencies?",e,reject))
        })
    }
    getGeoInfo <R>() : Promise<R> {
        this.lastRequest = Date.now();
         // this.baseUrl + `ips/getCountryInfo?&ipAddress=
        return  new Promise((resolve,reject)=> {
            this.axios.get("https://ipapi.co/json/", { withCredentials : false })
                .then(response => {resolve(response.data)})
                .catch(e => this.handleError("https://ipapi.co/json/",e,reject))
        })
      
    }
    getHooyu<R>(): Promise<R>  {
        this.lastRequest = Date.now();
        let clientId="a9e9fecb-9c99-4f0b-9648-c1aabd2ca23c";
        let secret="2926b8714d089bf8004915335eae4e63";
        let   lang="en";
        let  scope="selfie,documents";
        let    env="sandbox-Maharaja";
        let    email="sandinhenrik@gmail.com";
        let    name="Henrik Saxon";
        let    redirect_url="https://dev01.maharajafortune.com/";
       
        let data = JSON.stringify({
            "reference": "ref11",
            "environment": "sandbox-Maharaja",
            "name": "John Smith",
            "email": "sandinhenrik@gmail.com",
            "scope": "documents(passport)",
            "redirect_uri":redirect_url,
            "language":lang,
            "address": {
              "address1": "Unit 8-10",
              "town": "London",
              "postcode": "SW6 2UZ",
              "country": "GB"
            }
          });
        //   console.error('data',data);
        return  new Promise((resolve,reject)=> {
            this.axios.post("https://www.hooyu.com/api/1.8/request/create",data, { 
                 withCredentials : true,
                 headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Credentials':true,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With, Accept',
                    'Access-Control-Request-Headers': 'Content-Type, Authorization',
                    'Cookie': 'CUSTOMERCOOKIEID=49c746aa-cdf8-4aa1-b0a2-6dcb33f189c5; JSESSIONID=4E2C7D632E30474610F4585F4E16BD2C',
                    'Authorization': 'Basic YTllOWZlY2ItOWM5OS00ZjBiLTk2NDgtYzFhYWJkMmNhMjNjOjI5MjZiODcxNGQwODliZjgwMDQ5MTUzMzVlYWU0ZTYz'
            },
            proxy: createProxyMiddleware({ target: 'https://www.hooyu.com/api/1.8/request/create', changeOrigin: true})
            
         })
                .then(response => {
                    // console.error(response)
             
                    return resolve(response.data)})
                .catch(e =>{ this.handleError("https://www.hooyu.com/api/1.8/request/create",e,reject)})
        })
    }
    post<R,E>(url: string, entity : E) : Promise<R> {
        this.lastRequest = Date.now();
        console.error(entity);
        return  new Promise((resolve,reject)=> {
            this.axios.post(this.baseUrl + url  +`&brandId=${this.brandId}`,entity, { withCredentials : false,
                headers: {
                       'Cookie': ' SESSIONID=F188ADD5B55DACFDD5628F246936E776; brandid=1',
                    }
                   })
                .then(response => resolve(response.data))
                .catch(e => this.handleError(url,e,reject))
        })
    }
    put<R>(url : string) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.put(this.baseUrl + url  +`&brandId=${this.brandId}`, {},{ withCredentials : true })
                .then(response => resolve(response.data))
                .catch(e => this.handleError(url,e,reject))
        })
    }
    delete<R>(url:string) : Promise<R> {
        this.lastRequest = Date.now();
        return  new Promise((resolve,reject)=> {
            this.axios.delete(this.baseUrl + url  +`&brandId=${this.brandId}`, { withCredentials : true })
                .then(response => resolve(response.data))
                .catch(e => this.handleError(url,e,reject))
        })
    }
    shouldRedirectToAppLogin( url : string) {
        const token = localStorage.getItem("token");
        const salt = localStorage.getItem("salt");
        const sessionKey = localStorage.getItem("sessionKey");
        if(sessionKey) {
            return false;
        }
        if(url.indexOf("/user/authenticate") != -1 || url.indexOf("/user/logout") != -1) {
            return false;
        }
        return true;
    }
    handleError(url : string, e : any, reject : any) {
        console.log("handle error",e.response);
        if(e.response && e.response.status == 401 && this.shouldRedirectToAppLogin(url)) {
            document.location.pathname = "/app";
        } else {
            reject(e);
        }
    }

    handleCasinoError( e : any, reject : any) {
        console.log("handle error",e.response);
        if(e.response && e.response.status == 401 ) {
            document.location.pathname = "/app";
        } else {
            reject(e);
        }
    }
}