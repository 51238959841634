import React, { Fragment } from 'react';
import { getContainer } from '../../ioc/IOCSetup';
import "../SportsView.scss";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Page from "../../layout/Page";
import { fetchSettings } from "../../settings/SettingsActions";
import { AppState } from "../../RootReducer";
import { LaunchUrl, Settings, Currency } from '../../http/protocol';
import { LoginStatus } from '../../http/Types';
import { ContentModelName, PopupManager } from "../../contenful/types";
import { fetchContent } from "../../contenful/ContentfulActions";
import { ReactComponent as Spinner } from "../../assets/ball_loader.svg";
import { progressBar } from "../../utils/ProgressBar";
import Root from "../../layout/Root";
import { showLogin } from "../../user/UserActions";
import ReactDOM from 'react-dom';
import ReactGA from "react-ga4";
import { withRouter } from 'react-router-dom';
import { scrollTop } from '../../actions/HeaderActions';

type Props = {
    settings: Settings,
    fetchSettings: typeof fetchSettings,
    loggedIn?: boolean;
    currency?: string;
    popupManager?: PopupManager;
    showLogin: () => void;
    scrollTop:(position:any) => void;
    fetchContent: typeof fetchContent;
    lng: string;
    userId?: any;
    location : Location
};

interface State {
    sportsbookUrl?: string;
    iframeHeight?: number;
    loading: boolean;
    hideAfterWentToOtherPage: boolean;
    progress: number;
    manualProgressIncrease: boolean;
    betslipcount:number;
    betSlipshow:boolean;
    path?:string;
}

export class Song88SportsComponent extends React.Component<Props, State>{
    listener: any;
    progressInterval: any;
    constructor(props: Props) {
        super(props);
        let heightOffset = 80;
        if (window.innerWidth <= 1200) {
            heightOffset = 110;
        }

        this.state = { iframeHeight: window.innerHeight,betSlipshow:false, betslipcount:0, loading: true, hideAfterWentToOtherPage: false, progress: 0, manualProgressIncrease: true };
    }
    startProgress() {
        if (this.progressInterval) {
            clearInterval(this.progressInterval);
        }
        let current_progress: number = 0;
        let step: number = 0.1;
        this.setState({ loading: true, progress: 0, sportsbookUrl: undefined, manualProgressIncrease: true })
        this.progressInterval = setInterval(() => {
            if (this.state.manualProgressIncrease) {
                current_progress += step;
                let progress: number = Math.round(Math.atan(current_progress) / (Math.PI / 2) * 98 * 1000) / 1000;
                if (this.state.progress > 35) {
                    step = 0.4
                }
                this.setState({ progress: progress })
            }
        }, 200);
        setTimeout(() => {
            this.navigateToMaharajaBoost();
            this.setState({ loading: false })
        }, 5000)

    }
    componentDidMount(): void {
     
        this.startProgress();
        
  

        this.listener = window.addEventListener("message", (e) => {
            if (e.data.isLoaded) {
                this.setState({ progress: 100, manualProgressIncrease: false })
                setTimeout(() => this.setState({ loading: false, progress: 100, manualProgressIncrease: false }, () => clearInterval(this.progressInterval)), 250)
            }
            try {
                try {
                    var payload = JSON.parse(e.data);
                    // console.error(payload.eventType,"payload.eventType");
                    if (payload.eventType == 'on-page-height-change') {
                        // console.error(payload.eventData);
                        this.setState({ iframeHeight: payload.eventData.height })
                    }
                    //message 
                    if (payload.eventType == 'on-api-error' ) {
                        // console.error(payload.eventData, 'on-api-error', payload.eventData.Error.xsrfHeaderName);
                         if ((this.props.currency != undefined) && payload.eventData.Error.xsrfHeaderName == "X-XSRF-TOKEN") {
                            if (this.props.loggedIn && this.props.userId) {
                                getContainer().getSportsbookService().getSong88GameLaunchUrl(this.props.currency, locale).then((r: LaunchUrl) => {
                                    this.setState({ sportsbookUrl: song88url && song88url + "&currencyCode=" + this.props.currency + "&languageCode="+locale.substring(0,2)+"&operatorToken=" + r.launchToken + "&userId=" + this.props.userId + "-" + this.props.currency +"", loading: true, progress: 0 }, () => this.startProgress());
                                });
                            }
                        }
                    }
                    if (payload.eventType == 'on-betslip-count-update') {
                        this.setState({ betslipcount: payload.eventData.betslipSelectionsCount })
                       // @ts-ignore
                    //    let chat =  document.getElementById('fc_frame').style.display = "none";
                     
                     
                    }
                    if (payload.eventType == 'on-page-scroll-top') {
                            //@ts-ignore
                        var offsets = document.getElementById('sportsbook-iframe') && document.getElementById('sportsbook-iframe').getBoundingClientRect();
                           //@ts-ignore
                        var top = offsets.top;
                   
                        window.scrollTo({top: top + window.pageYOffset, behavior: 'smooth'});
            
                    }
                    if (payload.eventType == 'on-bet-section-expand') {
                        this.setState({ betSlipshow: false })
                    }
               
                    if (payload.eventType == 'on-bet-section-collapse') {
                        this.setState({ betSlipshow: true })
                   // @ts-ignore
                    //  let chat =  document.getElementById('fc_frame').style.display = "none";
                     
                     
                    }

                    if (payload.eventType == 'on-bet-placed-btn-clicked' && !this.props.loggedIn) {
                        this.props.showLogin();

                    } 
                    if(payload.eventType == 'on-bet-placed-btn-clicked' && this.props.loggedIn){
                        ReactGA.event({
                            category: "User",
                            action: "sportsbook-place-bet",
                          });
                          
                    }
                    
                } catch (e) {
                }


            } catch (e) {
                console.log(e);
            }
        }, false);
      




        if (this.props.settings == undefined) {
            this.props.fetchSettings();
        }

        let song88url = getContainer().getSettingsService().getSong88Url();
        const currencyOnLoad = localStorage.getItem("activeCurrency") || this.props.currency || "";
        let locale = getContainer().getSettingsService().getActiveLocale().code;
        let currency = getContainer().getSettingsService().getCurrencies();
        if (this.props.loggedIn) {
            getContainer().getSportsbookService().getSong88GameLaunchUrl(currencyOnLoad, locale).then((r: LaunchUrl) => {
                if(locale == "pt-BR") {
                this.setState({ sportsbookUrl: song88url && song88url  + "&currencyCode=" + currencyOnLoad + "&languageCode="+locale.substring(3,5)+"&operatorToken=" + r.launchToken + "&userId=" + this.props.userId + "-" + this.props.currency +"" });
                } else {
                    this.setState({ sportsbookUrl: song88url && song88url  + "&currencyCode=" + currencyOnLoad + "&languageCode="+locale.substring(0,2)+"&operatorToken=" + r.launchToken + "&userId=" + this.props.userId + "-" + this.props.currency +"" });
               
                }

            });
        }
   
        if (!this.props.loggedIn) {
            if(locale == "pt-BR") {
                this.setState({ sportsbookUrl:song88url && song88url + "&languageCode="+locale.substring(3,5)+"&currencyCode="+"BRL" });

            } else {
                this.setState({ sportsbookUrl:song88url && song88url + "&languageCode="+locale.substring(0,2)+"&currencyCode="+"BRL" });

            }
          
        }
    }

    onClickExpandBetSlip() {
        const sportsbookIframe = document.getElementById('sportsbook-iframe');
        const obj = ReactDOM.findDOMNode(sportsbookIframe) as HTMLIFrameElement;
               //@ts-ignore
               var offsets = document.getElementById('sportsbook-iframe') && document.getElementById('sportsbook-iframe').getBoundingClientRect();
               //@ts-ignore
            var top = offsets.top;
        window.scrollTo({top: top + window.pageYOffset, behavior: 'smooth'});

        const expandBetSlip = obj.contentWindow ? obj.contentWindow.postMessage(JSON.stringify({ 
            eventType: 'expand-bet-slip'   }), 
          '*' ) : null;
        // console.error('post a message', expandBetSlip, obj);
    }

    navigateToMaharajaBoost() {
        let lang = getContainer().getSettingsService().getLocalesData();


    let path ;
    if(lang && lang.length >= 2) {
             path = window.location.pathname.split("/")[3];
        } else {
           path = window.location.pathname.split("/")[2];
        }
        // console.error(path,"path")

        if(path === "maharajaboost") {
        const sportsbookIframe = document.getElementById('sportsbook-iframe');
        const obj = ReactDOM.findDOMNode(sportsbookIframe) as HTMLIFrameElement;
        const navigateToMaharajaBoost = obj.contentWindow ? obj.contentWindow.postMessage(JSON.stringify({ 
            eventType: 'navigate-to',
            eventData: {
                scope: "BOSSBOOSTS"
            }
        }), 
          '*' ) : null;
        // console.error('post a message', navigateToMaharajaBoost, obj);
         }
         if(path === "live") {
            const sportsbookIframe = document.getElementById('sportsbook-iframe');
            const obj = ReactDOM.findDOMNode(sportsbookIframe) as HTMLIFrameElement;
            const navigateToMaharajaBoost = obj.contentWindow ? obj.contentWindow.postMessage(JSON.stringify({ 
                eventType: 'navigate-to',
                eventData: {
                    scope: "LIVE"
                }
            }), 
              '*' ) : null;
            // console.error('post a message', navigateToMaharajaBoost, obj);
             }else {
                const sportsbookIframe = document.getElementById('sportsbook-iframe');
                const obj = ReactDOM.findDOMNode(sportsbookIframe) as HTMLIFrameElement;
                const navigateToMaharajaBoost = obj.contentWindow ? obj.contentWindow.postMessage(JSON.stringify({ 
                    eventType: 'navigate-to',
                    eventData: {
                        scope: "HOME"
                    }
                }), 
                  '*' ) : null;
                // console.error('post a message', navigateToMaharajaBoost, obj);
                 }
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
  

    
       
        let song88url = getContainer().getSettingsService().getSong88Url();
   
        let locale = getContainer().getSettingsService().getActiveLocale().code;
        if(prevProps.location != this.props.location){
            console.error(this.props.location,prevProps.location)
            this.navigateToMaharajaBoost();
        }
        if (prevProps.loggedIn != this.props.loggedIn && this.props.currency != undefined) {
            if (this.props.loggedIn && this.props.userId && song88url) {
                getContainer().getSportsbookService().getSong88GameLaunchUrl(this.props.currency, locale).then((r: LaunchUrl) => {
                    if(locale == "pt-BR") {
                        this.setState({ sportsbookUrl: song88url && song88url + "&currencyCode=" + this.props.currency + "&languageCode="+locale.substring(3,5)+"&operatorToken=" + r.launchToken + "&userId=" + this.props.userId + "-" + this.props.currency +"", loading: true, progress: 0 }, () => this.startProgress());
             
                    } else {
                        this.setState({ sportsbookUrl: song88url && song88url + "&currencyCode=" + this.props.currency + "&languageCode="+locale.substring(0,2)+"&operatorToken=" + r.launchToken + "&userId=" + this.props.userId + "-" + this.props.currency +"", loading: true, progress: 0 }, () => this.startProgress());
             
                    }
                });
            } else {
                if(locale == "pt-BR") {
                    this.setState({ sportsbookUrl: song88url && song88url + "&languageCode="+locale.substring(3,5)+"&currencyCode="+this.props.currency, loading: true, progress: 0 }, () => this.startProgress());
               
                } else {
                this.setState({ sportsbookUrl: song88url && song88url + "&languageCode="+locale.substring(0,2)+"&currencyCode="+this.props.currency, loading: true, progress: 0 }, () => this.startProgress());
                }
            }
        }

        if ((this.state.sportsbookUrl == undefined && this.props.currency != undefined)) {
            if (this.props.loggedIn && this.props.userId) {
                getContainer().getSportsbookService().getSong88GameLaunchUrl(this.props.currency, locale).then((r: LaunchUrl) => {
                    if(locale == "pt-BR") {
                        this.setState({ sportsbookUrl: song88url && song88url  + "&currencyCode=" +  this.props.currency + "&languageCode="+locale.substring(3,5)+"&operatorToken=" + r.launchToken + "&userId=" + this.props.userId + "-" + this.props.currency +"" }, () => { });
                   
                    } else {
                    this.setState({ sportsbookUrl: song88url && song88url  + "&currencyCode=" +  this.props.currency + "&languageCode="+locale.substring(0,2)+"&operatorToken=" + r.launchToken + "&userId=" + this.props.userId + "-" + this.props.currency +"" }, () => { });
                    }
                });
            } else {
                if(locale == "pt-BR") {
                    this.setState({ sportsbookUrl:song88url && song88url + "&languageCode="+locale.substring(3,5)+"&currencyCode="+this.props.currency });
             
                } else {
                this.setState({ sportsbookUrl:song88url && song88url + "&languageCode="+locale.substring(0,2)+"&currencyCode="+this.props.currency });
                }
            }
        } else if (this.props.currency != prevProps.currency && this.props.currency != undefined) {
            this.startProgress();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("message", this.listener);
           // @ts-ignore
        // document.getElementById('fc_frame').style.display = "block";
                     
    }

    loadContent() {
        // console.warn(this.state.sportsbookUrl,this.props.loggedIn,this.props.userId,this.props.currency);
        return (
            <>
                    {this.state.betSlipshow && this.state.betslipcount > 0  ? (
                <div className="show" id="expand-bet-slip" onClick={this.onClickExpandBetSlip}><div className="bet-slip-count">
                    {this.state.betslipcount}</div>
                    <i className="fa fa-th-list"></i></div>):null
                    }
            <iframe
                hidden={this.state.loading}
                id="sportsbook-iframe"
                scrolling="no"
                width={"100%"}
                height={this.state.iframeHeight}
                className={"sportsbook-iframe"}
                frameBorder={0}
                allowFullScreen={false}
                allowTransparency = {true}
                src={this.state.sportsbookUrl} />
            
            </>
        )
    }

    render() {
       
        
        return (
    
         
            <div>
              
                {this.state.loading && <div className="preloader">
                    <Spinner />
                    {progressBar(this.state.progress, "100px", "2px")}
                </div>}
                <div>
                    {this.loadContent()}
                    </div>
            </div>
        

        )
    }
}

const stateToProps = (app: AppState) => {

    return {
        settings: app.settings.settings ? app.settings.settings : {},
        loggedIn: app.user.status == LoginStatus.LOGGED_IN,
        currency: app.user.activeCurrency,
        userId: app.user.user ? app.user.user.partyId : null

    }
};

const dispatchToProps = (dispatch: Dispatch) => (
    {
        fetchSettings: () => dispatch(fetchSettings() as any),
        fetchContent: (contentModelName: ContentModelName) => dispatch(fetchContent(contentModelName) as any),
        showLogin: () => dispatch(showLogin(true)),
        scrollTop: (position: any = 0) => dispatch(scrollTop(position) as any)
    }
);
export default connect(stateToProps, dispatchToProps)(withRouter(Song88SportsComponent as any));

