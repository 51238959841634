import * as React from "react";
import "./styles/FooterComponent.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { selectCategory, selectCategoryName } from "../casino/CasinoActions";
import { Link } from "react-router-dom";
import { FooterPayload, InfoPagePayload, Locale } from "../contenful/types";
import CTAButton, { CTAButtonType } from "../layout/CTAButton";
import footLogoUrl, { imageUrl } from "../utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faSignInAlt, faDiceFive } from "@fortawesome/free-solid-svg-icons";
import FooterTextButton from "./FooterTextButton";
import DEFAULT_AVATAR from "https://content.adminemx.com/maharajafortune/images/user.png";
import Dropdown from "react-bootstrap/Dropdown";
import { getContainer } from "../ioc/IOCSetup";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { CasinoCurrencies, CasinoLobbyCategory, CasinoStudio } from "../http/protocol";
import { toSEOFriendlyString } from "../user/ui/utils";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import {isMobile} from "react-device-detect";
import {t} from "../i18n";

import {
  faPiggyBank,
  faCashRegister,
  faHistory,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";
import { showCashierModal } from "../user/UserActions";
interface Props extends RouteComponentProps {
  footer: FooterPayload;
  loggedIn: boolean;
  logInClicked: () => void;
  signUpClicked: () => void;
  logOutClicked: () => void;
  openNav: (event: any) => void;
  lng: string;
  user: any;
  scrollVal: any;
  balances: any;
  scrollTop: () => void;
  selectCategory?: (cat: number) => void;
  selectCategoryName?: (catName: string) => void;
  showCashierModal?:(selectedCashier:string) => void;
  path?: string;

}

export class FooterComponentJoiabet extends React.Component<Props> {
  locales: Array<Locale> | undefined;
  constructor(props: Props) {
    super(props);

    this.locales = getContainer().getSettingsService().getLocalesData();

  }

  handleOnClick = (catid: any, catname: any) => {
    document.body.classList.remove('nav-open');
    if (catid && catname) {
      this.props.selectCategory && this.props.selectCategory(catid);

      this.props.selectCategoryName && this.props.selectCategoryName(catname);
      this.props.history.push(`/casino/${catid}/${toSEOFriendlyString(catname)}`);
    }
  }

  handleHome = () => {
    document.body.classList.remove('nav-open');
    this.props.history.push(`/`);

  }

  handleCasino = () => {
    document.body.classList.remove('nav-open');
    this.props.history.push(`/`);

  }


  handlePromotion = () => {
    document.body.classList.remove('nav-open');
    this.props.history.push(`/promotion`);

  }
  handleLivecasino = () => {
    document.body.classList.remove('nav-open');
    this.props.history.push(`/livecasino/45/popular-live%20games`);

  }
  handleClickremoveNavOpen = () => {
    document.body.classList.remove('nav-open');
  }
  render() {
    let brandLogo = getContainer().getSettingsService().getBrandLogo();
    return (
      <React.Fragment>
        <footer className="mb-0">
          <div className="container-fluid">
            <div className="block-space">
              <div className="row d-flex  flex-wrap-reverse">
                <div className="col-lg-4 text-center-footer">

                  <div className="footerLogoBG" >
                    {brandLogo ? ( <img
                      src={brandLogo}
                      className="img-fluid footer-logo"
                      title="Logo"
                      alt="Logo"
                    />):( <img
                      src={"https://content.adminemx.com/maharajafortune/images/footer-logo.png"}
                      className="img-fluid footer-logo"
                      title="Logo"
                      alt="Maharajafortune"
                    />)}
                   
                  </div>
                  <p>{t("footerTexts.text1")}</p>
                  {/* <div className="service-code">Service Code</div> */}
                  <p className="footer-tm"> {t("footerTexts.text2")}</p>
                  {t("footerTexts.text3")}

                  <br />

                  <br />

                  <a href="https://licensing.gaming-curacao.com/validator/?lh=5eed57d7b23ea9c224114239e8758e3d" target="_blank"><img
                    src="https://licensing.gaming-curacao.com/validator/images/Gaming-Curacao-ClickToVerify.png"
                    width="150" height="50" alt="Gaming Curacao: Click to Validate" /></a>
                  <div className="d-lg-block d-md-block d-sm-none d-none">
                    <div className="col-lg-12 col-md-12 col-6">
                      <p className="footer-title text-left">     {t("legal")}</p>
                      <ul className="f-link list-unstyled d-flex flex-wrap">
                        <li>
                          <Link to={"/privacy-policy"} onClick={this.props.scrollTop}> {t("privacyPolicy")}</Link>
                        </li>
                        <li>
                          <Link to={"/cookie-policy"} onClick={this.props.scrollTop}> {t("cookiePolicy")}</Link>
                        </li>
                        <li>
                          <Link to={"/terms"} onClick={this.props.scrollTop}>   {t("generalTerms")}</Link>
                        </li>
                        <li>
                          <Link to={"/bonus-terms"} onClick={this.props.scrollTop}>    {t("bonusTerms")}</Link>
                        </li>
                        <li>
                          <Link to={"/responsible-gaming"} onClick={this.props.scrollTop}> {t("responsibleGaming")}</Link>
                        </li>

                        <li>
                          <Link to={"/kyc-policy"} onClick={this.props.scrollTop}> {t("kycPolicy")}</Link>
                        </li>
                        <li>
                          <Link to={"/aml-policy"} onClick={this.props.scrollTop}> {t("amlPolicy")}</Link>
                        </li>

                      </ul>
                    </div>
                    <div className="col-lg-12 col-md-12 col-6">
                      <p className="footer-title text-left"> {t("useful")}</p>
                      <ul className="f-link list-unstyled d-flex flex-wrap">

                        <li>
                          <Link to={"/promotions"} onClick={this.props.scrollTop}>   {t("promotions")}</Link>
                        </li>
                   
                        <li>
                          <Link to={"/faq"} onClick={this.props.scrollTop}>  {t("helpAndFAQ")}  </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-12 col-md-12 col-6">
                      <p className="footer-title text-left"> {t("general")}</p>
                      <ul className="f-link list-unstyled d-flex flex-wrap">

                        {/* <li>
                          <Link to={"/about-us"} onClick={this.props.scrollTop}>  {t("aboutUs")} </Link>
                        </li> */}
                        <li>
                          <a href={"https://emixpartners.com/"} target="_blank">   {t("affiliates")} </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 second">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="footer-title">
                        {t("gameProviders")}
                      </div>

                      <ul className="footer-brands">
                   
                     
                      <li><img src={"   https://content.adminemx.com/game_provider/1X2.png"} alt="PlaynGo" /></li>
                    
                      <li><img src={" https://content.adminemx.com/game_provider/DARWIN.png"} alt="DARWIN" /></li>
                      <li><img src={" https://content.adminemx.com/game_provider/GALAXYS.png"} alt="PlaynGo" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/PLAYNGO.png"} alt="PlaynGo" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/QUICKSPIN.png"} alt="Quickspin" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/PRAGMATICPLAY.png"} alt="Pragmatic-Play" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/MICROGAMING.png"} alt="Microgaming" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/BLUEPRINT.png"} alt="Blueprint" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/CALETA.png"} alt="caleta" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/NETENT.png"} alt="netent" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/RELAXGAMING.png"} alt="Relax-Gaming" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/PLAYSON.png"} alt="Playson" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/BOOMINGGAMES.png"} alt="booming games" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/REDTIGER.png"} alt="Red-Tiger" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/ONETOUCH.png"} alt="onetouch" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/TOMHORN.png"} alt="Tom-Horn" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/EVOPLAY.png"} alt="Evoplay" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/GAMEART.png"} alt="GameArt" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/HABANERO.png"} alt="Habanero" /></li>

                        <li><img src={"https://content.adminemx.com/game_provider/SPRIBE.png"} alt="spribe" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/WAZDAN.png"} alt="Wazdan" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/REDRAKE.png"} alt="Red-Rake" /></li>
                       
                         <li><img src={"https://content.adminemx.com/game_provider/4THEPLAYER.png"} alt="4ThePlayers" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/EVOLUTION.png"} alt="Evolution" /></li>
                        <li><img src={"https://content.adminemx.com/game_provider/TVBET.png"} alt="TVBet" /></li>


                      </ul>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="footer-title">
                        {t("paymentProviders")}
                      </div>

                      <ul className="footer-brands">


                        <li><img src={"https://content.adminemx.com/maharajafortune/images/payments/SVGICONS/CASHIP_PIX.svg"} alt="Paytm" /></li>

                      </ul>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="footer-title">
                      
                      {t("partner")}
                      </div>
                        <a href="https://www.onlinecassino.com.br/" target="_blank" >
                     <img src={"https://content.adminemx.com/joiabet/onlinecassino.com.br.svg"} alt="onlinecassino" style={{"width":"160px"}}className="img-fluid"/>
                     </a>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="footer-title">
                      
                      {t("social")}
                      </div>
                        <a href="https://www.facebook.com/joiabet" target="_blank" >
                     <img src={"https://content.adminemx.com/joiabet/socials/icons8-facebook-480.svg"} alt="onlinecassino" style={{"width":"60px"}}className="img-fluid"/>
                     </a> 
                     <a href="https://www.instagram.com/joiabet/" target="_blank" >
                     <img src={"https://content.adminemx.com/joiabet/socials/icons8-instagram-480.svg"} alt="instagram" style={{"width":"60px"}}className="img-fluid"/>
                     </a>
                  
                     <a href="https://twitter.com/JoiaBet" target="_blank" >
                     <img src={"https://content.adminemx.com/joiabet/socials/icons8-twitterx-480.svg"} alt="twitter" style={{"width":"60px"}}className="img-fluid"/>
                     </a>
                     <a href="https://t.me/joiabetpromos" target="_blank" >
                     <img src={"https://content.adminemx.com/joiabet/socials/icons8-telegram-480.svg"} alt="telegram" style={{"width":"60px"}}className="img-fluid"/>
                     </a>
                    </div>
                    <div className="row"></div>

                    <div className="col-lg-12 col-md-12 col-6 d-sm-block d-lg-none d-md-none ">
                      <p className="footer-title">   {t("legal")}</p>
                      <ul className="f-link list-unstyled ">
                        <li>
                          <Link to={"/privacy-policy"} onClick={this.props.scrollTop}> {t("privacyPolicy")}</Link>

                        </li>
                        <li>
                          <Link to={"/cookie-policy"} onClick={this.props.scrollTop}> {t("cookiePolicy")}</Link>
                        </li>
                        <li>
                          <Link to={"/terms"} onClick={this.props.scrollTop}>   {t("termsAndConditions")}</Link>
                        </li>
                        <li>
                          <Link to={"/bonus-terms"} onClick={this.props.scrollTop}>   {t("bonusTerms")}</Link>
                        </li>
                        <li>
                          <Link to={"/responsible-gaming"} onClick={this.props.scrollTop}> {t("responsibleGaming")}</Link>
                        </li>

                        <li>
                          <Link to={"/kyc-policy"} onClick={this.props.scrollTop}> {t("kycPolicy")}</Link>
                        </li>
                        <li>
                          <Link to={"/aml-policy"} onClick={this.props.scrollTop}> {t("amlPolicy")}</Link>
                        </li>

                      </ul>

                    </div>
                    <div className="col-lg-12 col-md-12 col-6 d-sm-block d-lg-none d-md-none ">
                      <p className="footer-title">  {t("useful")}</p>
                      <ul className="f-link list-unstyled ">

                      <li>
                          <Link to={"/promotions"} onClick={this.props.scrollTop}>   {t("promotions")}</Link>
                        </li>
                  
                        <li>
                          <Link to={"/faq"} onClick={this.props.scrollTop}>  {t("helpAndFAQ")}  </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-12 col-md-12 col-6 d-sm-block d-lg-none d-md-none ">
                      <p className="footer-title">  {t("general")} </p>
                      <ul className="f-link list-unstyled ">

                   
                        <li>
                          <a href={"https://emixpartners.com/"} target="_blank">   {t("affiliates")} </a>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            <div className="col-xs-12" style={{ textAlign: "center", paddingBottom: 20 }}>
          
              <div className="last-text-footer">

              </div>
            </div>
          </div>
     
        </footer>


        {/* Mobile Fixed Nav */}
     <div className="mobile-fixed-nav">
          <ul className="mobile-menu">
          <li className="menu-item">
              <a onClick={this.props.openNav} className="menu-link">
                <img src={"https://content.adminemx.com/maharajafortune/images/mobilenav/more.png"} className="mobile-nav-icon" />
                <span>More</span>
              </a>
            </li>
          {this.props.loggedIn ?
             null : (
              <React.Fragment>
                <li className="menu-item log-in-mobile">
                  <a onClick={this.props.logInClicked} className="menu-link">
                  <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/log-in.svg"} className="mobile-nav-icon" />
                    <span> {t("logIn")}</span>
                  </a>
                </li>
                <li className="menu-item sign-up-mobile">
                        <a onClick={this.props.signUpClicked} className="menu-link">
                          <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/sign-up.svg"} className="mobile-nav-icon" />
                          <span>{t("signUp")}</span>
                        </a>
                 </li>
                 </React.Fragment>
              )}
            <li className="menu-item">
              <Link to={"/casino"} className="menu-link">
              <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/casino.svg"} className="mobile-nav-icon" />
                <span>{t("casino")}</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link to={"/sports"} className="menu-link">
              <img src={"https://content.adminemx.com/joiabet/icons/soccer-ball.svg"} className="mobile-nav-icon" />
                <span>{t("sports")}</span>
              </Link>
            </li>
            {this.props.loggedIn ?
            (<li className="menu-item">
              <Link to={"/casino/33/crash-games"} className="menu-link">
              <img src={"https://content.adminemx.com/joiabet/icons/crash-games.svg"} className="mobile-nav-icon" />
                <span>{t("homePageTexts.crashGames")}</span>
              </Link>
            </li>) : null}
            {this.props.loggedIn ?
              (<li className="menu-item">
                <a  onClick={() => {this.handleClickremoveNavOpen(); this.props.showCashierModal &&  this.props.showCashierModal("deposit");} }   className="menu-link">
                <img src={"https://content.adminemx.com/maharajafortune/images/header-icons-v2/deposit.svg"} className="mobile-nav-icon" />
                <span>{t("deposit")}</span>
                </a>
              </li>) : null}
            
          </ul>


        </div><div onClick={this.props.scrollTop} className={this.props.scrollVal > 50 ? 'show' : ''} id="back-to-top">
          <div className="top" id="top"> <i className="fa fa-angle-up"></i> </div>
        </div>
     
        {/* Mobile Fixed Nav */}
       
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => (
  {
    selectCategory: (cat: number) => dispatch(selectCategory(cat)),
    selectCategoryName: (catName: string) => dispatch(selectCategoryName(catName)),
    showCashierModal:(selectedCashier:string)=> dispatch(showCashierModal(true,selectedCashier)),
  }
)
export default connect(null, mapDispatchToProps)(withRouter<Props>(FooterComponentJoiabet));
